@import url(https://use.typekit.net/gdp5bqu.css);
body {
    margin: 0;
    font-family: "Arno Pro Regular" ;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FFF;
    color: hotpink;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    text-align: center;
}

header {
    padding-bottom: 50px;
    background-color: #FFFFFF;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: hotpink;
}

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: #FFFFFF;
}

nav {
    border-bottom: solid 1px;
    padding-bottom: 1rem;
}

.push {
    height: 150px;
}

footer {
    height: 50px;
    background: #FFFFFF;
    line-height: 50px;
    padding: 20px;
    text-align: center;
}

footer div {
    color: hotpink;
}

footer a img {
    width: 150px;
}

.card {
    margin: 20px;
    padding: 10px;
    border: 3px solid hotpink;
    border-radius: 10px;
    background-color: #1a2225;
    width: 200px;
}

.card div a {
    color: hotpink;
}

.card div a:hover {
    color: firebrick;
}

.cardTile {
    width: 100%;
    display: block;
}

.cardTile img {
    display: inline-block;
    background-color: black;
    border: white solid 1px;
    width: 100px;
    height: 75px;
}

#username {
    margin: 10px;
}

